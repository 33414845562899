<template>
  <div>
    <div class="mb-1 text-left">
      <b-button
        v-if="allows_crud"
        class="mt-1 ml-1"
        size="sm"
        variant="primary"
        @click="$bvModal.show(`modal-update-question-test-${question_test_id}`)"
        >Editar Instrumento</b-button
      >
      <b-button
        v-if="!user.is_student"
        class="mt-1 ml-1 noprint"
        size="sm"
        variant="primary"
        @click="$bvModal.show(`modal-specification-table-${question_test_id}`)"
        >Tabla de Especificaciones</b-button
      >
    </div>

    <div class="test-container p-1">
      <div v-if="questionTest && user.is_student">
        <template v-if="evaluation_not_started_yet && !is_running">
          <div class="time-label start-time-label">
            La evaluación comenzará el "<strong>{{
              new Date(questionTest.start_date) | FormatToDateTime
            }}</strong
            >" Dentro de:
          </div>
          <div class="time-card not-started-yet">
            <strong>
              <Timer :end_date="questionTest.start_date" />
            </strong>
          </div>
        </template>
        <template v-else>
          <div
            v-if="
              (is_running &&
                questionTestScore &&
                !questionTestScore.is_finished) ||
              (is_running && !questionTestScore)
            "
            class="test-timer"
            :class="{ 'test-timer-sticky': window_top > 318 }"
          >
            <div v-if="window_top < 318">
              Tiempo Restante
              <Timer
                :start_date="questionTest.start_date"
                :end_date="questionTest.due_date"
              />
            </div>
            <div v-else>
              <Timer
                :start_date="questionTest.start_date"
                :end_date="questionTest.due_date"
                :format_visible="false"
                :flex_adjust="window_top > 600 ? true : false"
              >
                <template v-slot:flex_content>
                  <div
                    v-if="window_top > 600"
                    class="questions-answered-sticky"
                  >
                    <b-progress
                      v-if="is_running && !questionTestScore.is_finished"
                      height="1.5em"
                      variant="primary"
                      :max="questionList.length"
                    >
                      <b-progress-bar
                        variant="info"
                        :value="
                          created_redaction_answer + created_select_answer
                        "
                        animated
                      >
                        <span
                          >Preguntas respondidas:
                          <strong
                            >{{
                              created_redaction_answer + created_select_answer
                            }}
                            / {{ questionList.length }}</strong
                          ></span
                        >
                      </b-progress-bar>
                    </b-progress>
                  </div>
                </template>
              </Timer>
            </div>
          </div>
          <template v-else>
            <div class="time-label left-time-label">Evaluación finalizada</div>
          </template>
        </template>
      </div>
      <div></div>
      <!-- ############### Vista de Instrumento para Docente ############### -->
      <InstrumentHeader
        :key="key_component"
        :Instrument="questionTest"
        :TestForm="true"
        :allows_crud="allows_crud"
        :is_running="is_running"
        :is_finished="questionTestScore ? questionTestScore.is_finished : false"
        :instrumentScore="questionTestScore ? questionTestScore : null"
        :evaluation_not_started_yet="evaluation_not_started_yet"
        :is_student="user.is_student ? true : false"
        :instrumentStat="sectionStat"
        :is_professor="user.is_professor ? true : false"
        :questions_current_score="questions_total_score"
      ></InstrumentHeader>
      <div v-if="user.is_professor" :ref="'instrument'">
        <draggable
          v-model="questionList"
          :disabled="!allows_crud"
          handle=".handle"
        >
          <transition-group>
            <template>
              <div
                v-for="(question, index) in questionList"
                :key="question.id"
                class="card-section"
              >
                <template v-if="question.question_type == 1">
                  <div class="card-item">
                    <RedactionQuestion
                      :key="question.order + key_component"
                      :Question="question"
                      :evaluation_id="evaluation_id"
                      :allows_crud="allows_crud"
                      :is_selected="question.id == selected_question"
                      :is_running="is_running"
                      :can_answer="can_answer"
                      :question_added="question_added"
                      @selected="slotSelectedQuestion"
                      @createRedactionAnswer="emitCreatedRedactionAnswer"
                      @questionPatched="slotQuestionPatched"
                      @redactionQuestionScore="slotRedactionQuestionScore"
                      @deletedQuestion="slotDeletedQuestion"
                      @questionCopied="slotQuestionCopied"
                      @questionScoreUpdated="slotQuestionScoreUpdated"
                      :class="{
                        'last-question-added':
                          question_added && index == questionList.length - 1,
                      }"
                    ></RedactionQuestion>
                  </div>
                </template>
                <template v-else-if="question.question_type == 2">
                  <div class="card-item">
                    <SimgleSelectQuestion
                      :key="question.order + key_component"
                      :Question="question"
                      :evaluation_id="evaluation_id"
                      :allows_crud="allows_crud"
                      :is_selected="question.id == selected_question"
                      :is_running="is_running"
                      :can_answer="can_answer"
                      @selected="slotSelectedQuestion"
                      @createSelectAnswer="emitCreatedSelectAnswer"
                      @questionPatched="slotQuestionPatched"
                      @selectQuestionScore="slotSelectQuestionScore"
                      @deletedQuestion="slotDeletedQuestion"
                      @questionCopied="slotQuestionCopied"
                      @questionScoreUpdated="slotQuestionScoreUpdated"
                      :class="{
                        'last-question-added':
                          question_added && index == questionList.length - 1,
                      }"
                    ></SimgleSelectQuestion>
                  </div>
                </template>
              </div>
            </template>
          </transition-group>
        </draggable>
        <b-button
          v-if="allows_crud"
          class="mt-2"
          variant="primary"
          @click="$bvModal.show(`modal-add-question-${question_test_id}`)"
          ><b-icon icon="plus"></b-icon> Agregar pregunta</b-button
        >
        <b-modal
          :id="`modal-add-question-${question_test_id}`"
          title="Crear Pregunta"
          size="xl"
          hide-footer
        >
          <QuestionForm
            :question_test_id="question_test_id"
            :evaluation_id="evaluation_id"
            :default_order="questionList.length + 1"
            @created="slotCreatedQuestion"
          ></QuestionForm>
        </b-modal>
      </div>

      <!-- ############### Vista de Instrumento para Estudiante ############### -->
      <div v-if="!evaluation_not_started_yet && user.is_student">
        <b-button
          v-if="!questionTestScore && is_running"
          @click="startInstrument"
          >Iniciar Instrumento</b-button
        >
        <div v-else-if="questionTestScore">
          <b-button
            v-if="questionTestScore.is_started_date == null && is_running"
            @click="startInstrument"
            >Iniciar Instrumento</b-button
          >
          <b-button
            v-if="
              questionTest.retake_instrument &&
              questionTestScore.is_finished &&
              questionTestScore.attempted_retakes !=
                questionTest.retake_instrument_attempts &&
              is_running
            "
            class="px-5 mb-2"
            variant="primary"
            size="sm"
            @click="retakeInstrument()"
          >
            Retomar Instrumento</b-button
          >
          <template
            v-if="questionTestScore.is_started_date != null || !is_running"
          >
            <b-progress
              v-if="
                is_running && !questionTestScore.is_finished && window_top < 600
              "
              height="2em"
              variant="primary"
              :max="questionList.length"
            >
              <b-progress-bar
                variant="info"
                :value="created_redaction_answer + created_select_answer"
                animated
              >
                <span
                  >Preguntas respondidas:
                  <strong
                    >{{ created_redaction_answer + created_select_answer }} /
                    {{ questionList.length }}</strong
                  ></span
                >
              </b-progress-bar>
            </b-progress>

            <draggable v-model="questionList" :disabled="!allows_crud">
              <transition-group>
                <template>
                  <div
                    v-for="(question, index) in questionList"
                    :key="question.id"
                    class="card-section"
                    :ref="'question' + question.id"
                  >
                    <template v-if="question.question_type == 1">
                      <div class="card-item">
                        <RedactionQuestion
                          :id="`question-${question.id}`"
                          :key="question.order + key_component"
                          :Question="question"
                          :evaluation_id="evaluation_id"
                          :allows_crud="allows_crud"
                          :is_selected="question.id == selected_question"
                          :is_running="is_running"
                          :can_answer="can_answer"
                          :instrumentScore="
                            questionTestScore ? questionTestScore : null
                          "
                          :questionTestScore="questionTestScore"
                          :is_finished="questionTestScore.is_finished"
                          :save_attempt="save_attempt"
                          :question_added="question_added"
                          @selected="slotSelectedQuestion"
                          @createRedactionAnswer="emitCreatedRedactionAnswer"
                          @updateRedactionAnswer="emitUpdatedRedactionAnswer"
                          :class="{
                            'last-question-added':
                              question_added &&
                              index == questionList.length - 1,
                          }"
                          @emptyAnswer="emitEmptyAnswer"
                          @isSaving="emitSaving"
                        ></RedactionQuestion>
                      </div>
                    </template>
                    <template v-else-if="question.question_type == 2">
                      <div class="card-item">
                        <SimgleSelectQuestion
                          :key="question.order + key_component"
                          :Question="question"
                          :evaluation_id="evaluation_id"
                          :allows_crud="allows_crud"
                          :is_selected="question.id == selected_question"
                          :questionTestScore="questionTestScore"
                          :is_running="is_running"
                          :can_answer="can_answer"
                          :instrumentScore="
                            questionTestScore ? questionTestScore : null
                          "
                          :is_finished="questionTestScore.is_finished"
                          :save_attempt="save_attempt"
                          @selected="slotSelectedQuestion"
                          @createSelectAnswer="emitCreatedSelectAnswer"
                          @updateSelectAnswer="emitUpdatedSelectAnswer"
                          :class="{
                            'last-question-added':
                              question_added &&
                              index == questionList.length - 1,
                          }"
                          @emptyAnswer="emitEmptyAnswer"
                          @isSaving="emitSaving"
                        ></SimgleSelectQuestion>
                      </div>
                    </template>
                  </div>
                </template>
              </transition-group>
            </draggable>
            <b-button
              v-if="
                is_running && user.is_student && !questionTestScore.is_finished
              "
              @click="closeInstrument"
              :disabled="is_saving > 0"
            >
              Finalizar Instrumento
            </b-button>
          </template>
        </div>
      </div>
      <div
        v-else-if="evaluation_not_started_yet && user.is_student"
        class="py-3"
      >
        Este instrumento aún no ha comenzado.
      </div>
    </div>
    <!-- Modals -->
    <b-modal
      :id="`modal-update-question-test-${question_test_id}`"
      title="Editar Instrumento"
      size="lg"
      hide-footer
    >
      <QuestionTestForm
        :QuestionTest="questionTest"
        :TestForm="true"
        :evaluation_id="questionTest.evaluation"
        :show_delete_button="true"
        @updated="slotQuestionTestUpdated"
      ></QuestionTestForm>
      <!-- @stateChanged="slotStateChanged" -->
    </b-modal>
    <div id="printSection">
      <b-modal
        :id="`modal-specification-table-${question_test_id}`"
        title="Tabla de Especificaciones"
        size="xl"
        hide-footer
        modal-class="custom-modal"
      >
        <div>
          <SpecificationTableView
            :QuestionTest="questionTest"
            :evaluation_id="questionTest.evaluation"
            :allows_crud="allows_crud"
            @specificationTableQuestion="specificationTableQuestion"
            @printerEvent="printStateChanged"
          ></SpecificationTableView>
        </div>
        <!-- @stateChanged="slotStateChanged" -->
      </b-modal>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import draggable from "vuedraggable";
import { customToast } from "@/utils/utils";

export default {
  name: "TestMaker",
  components: {
    draggable,
    RedactionQuestion: () => import("./RedactionQuestion"),
    SimgleSelectQuestion: () => import("./SimgleSelectQuestion"),
    QuestionTestForm: () => import("./QuestionTestForm"),
    QuestionForm: () => import("./QuestionForm"),
    InstrumentHeader: () => import("../InstrumentHeader.vue"),
    Timer: () => import("@/components/reusable/Timer.vue"),
    SpecificationTableView: () =>
      import("../SpecificationTable/SpecificationTableView.vue"),
  },
  props: {
    question_test_id: {
      type: Number,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
    evaluation_id: {
      type: Number,
      required: true,
    },
    section_evaluation_stats: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      selected_question: null,
      selected_question_type: null,
      question_added: false,
      // questionList: [],
      is_running: false,
      is_finished: false,
      can_edit_evaluation: false,
      evaluation_not_started_yet: false,
      time_to_start: null,
      time_to_end: null,
      time_elapsed_to_start: null,
      time_elapsed_to_end: null,
      can_answer: false,
      empty_question: false,
      created_select_answer: 0,
      key_component: 0,
      created_redaction_answer: 0,
      window_top: 0,
      save_attempt: 0,
      question_tests_score: [],
      current_tests: [],
      empty_questions: [],
      is_saving: 0,
      print_state: false,
      toggle_specification_table: false,
      questions_total_score: 0,
    };
  },
  computed: {
    ...mapGetters({
      allQuestionOptions: names.MULTIPLE_CHOICE_QUESTION_OPTIONS,
      questions: names.QUESTIONS,
      questionTests: names.QUESTION_TESTS,
      questionTestsScore: names.QUESTION_TESTS_SCORE,
      questionTypes: names.QUESTION_TYPES,
      sections: names.SECTIONS,
      evaluations: names.EVALUATIONS,
      user: "getUser",
    }),
    questionTest() {
      return this.questionTests.find((x) => x.id == this.question_test_id);
    },
    questionTestScore() {
      if (!this.user.is_student) return null;
      return this.questionTestsScore.find(
        (x) =>
          x.question_test == this.question_test_id && x.student == this.user.id
      );
    },
    sectionStat() {
      if (this.section_evaluation_stats == null) return null;
      else
        return this.section_evaluation_stats.find(
          (x) =>
            x.instrument_id == this.question_test_id &&
            x.instrument_type == "question_test"
        );
    },
    evaluation() {
      return this.evaluations.find((x) => x.id == this.evaluation_id);
    },
    canEditEvaluation() {
      let flag = false;
      if (!this.evaluation) return flag;
      if (
        this.user.is_professor &&
        (this.evaluation.editors.includes(this.user.id) ||
          this.evaluation.created_by == this.user.id)
      ) {
        flag = true;
      }
      return flag;
    },
    evaluationQuestions() {
      return this.questions.filter(
        (x) => x.question_test == this.question_test_id
      );
    },
    questionList: {
      get() {
        let list = this.evaluationQuestions;
        list.sort(function (a, b) {
          if (a.order < b.order) return -1;
          if (a.order > b.order) return 1;
          return 0;
        });
        let order = 1;
        list.forEach((element) => {
          if (element.order != order) element.order = order;
          order += 1;
        });
        return list;
      },
      set(list) {
        let order = 1;
        list.forEach((element) => {
          if (element.order != order) {
            element.order = order;
            if (
              !isNaN(element.id) &&
              this.questions.findIndex((x) => x.id == element.id) != -1
            ) {
              let payload = {
                question_id: element.id,
                item: {
                  order: element.order,
                },
              };
              this.$store.dispatch(names.PATCH_QUESTION, payload);
            }
          }
          order += 1;
        });
      },
    },
  },
  methods: {
    slotCreatedQuestion(question) {
      this.questions_total_score = 0;
      setTimeout(() => {
        this.questions_total_score += question.score;
        this.key_component += 1;
      }, 300);
      this.question_added = !this.question_added;
      setTimeout(() => {
        this.question_added = !this.question_added;
      }, 5000);
    },
    slotQuestionScoreUpdated(question_score) {
      setTimeout(() => {
        this.questions_total_score += parseInt(question_score);
        this.key_component += 1;
      }, 300);
    },
    slotQuestionCopied(question) {
      this.questions_total_score += question.score;
      setTimeout(() => {
        this.key_component += 1;
      }, 300);
    },
    slotDeletedQuestion(question) {
      this.questions_total_score = this.questions_total_score - question.score;
      setTimeout(() => {
        this.key_component += 1;
      }, 300);
    },
    slotSelectedQuestion(question_id) {
      if ((this.user.is_student && this.is_running) || this.user.is_professor) {
        this.selected_question = question_id;
      } else {
        this.selected_question = null;
      }
    },
    slotQuestionPatched() {
      this.key_component += 1;
      // setTimeout(() => {
      //   window.scrollTo(0, 540);
      // }, 200);
    },
    slotRedactionQuestionScore(question_score) {
      this.questions_total_score = 0;
      setTimeout(() => {
        this.questions_total_score += question_score;
      }, 300);
    },
    slotSelectQuestionScore(question_score) {
      this.questions_total_score = 0;
      setTimeout(() => {
        this.questions_total_score += question_score;
      }, 300);
    },
    emitUpdatedSelectAnswer(answer) {
      if (answer.selected == 0) {
        this.empty_question = !this.empty_question;
      } else {
        this.empty_question = false;
      }
    },
    emitUpdatedRedactionAnswer(answer) {
      if (answer.answer == "") {
        this.empty_question = !this.empty_question;
      } else {
        this.empty_question = false;
      }
    },
    emitEmptyAnswer(item) {
      let index = this.empty_questions.findIndex(
        (x) => x.question_id == item.question_id
      );
      if (index != -1) this.empty_questions.splice(index, 1, item);
      else this.empty_questions.push(item);
    },
    goto(refName) {
      var element = this.$refs[refName];
      var top = element[0].offsetTop;
      window.scrollTo(0, top);
    },
    scrollTest() {
      let empty_index = this.empty_questions.findIndex((x) => x.answer == "");
      if (empty_index != -1) {
        this.goto("question" + this.empty_questions[empty_index].question_id);
      }
    },
    slotQuestionTestUpdated() {
      this.$bvModal.hide(`modal-update-question-test-${this.question_test_id}`);
    },
    closeInstrument() {
      if (this.questionTestScore) {
        if (
          this.created_redaction_answer + this.created_select_answer <
            this.questionList.length ||
          this.empty_question
        ) {
          this.$swal({
            title:
              "<p>¿Está seguro/a de que desea <strong>finalizar</strong> el instrumento?</p>",
            text: "Aún quedan preguntas sin contestar.",
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "Estoy seguro/a",
            cancelButtonText: "Seguir respondiendo",
          }).then((result) => {
            if (result.value) {
              this.save_attempt += 1;
              let payload = {
                question_test_score_id: this.questionTestScore.id,
                item: {
                  is_finished: true,
                },
              };
              this.$store
                .dispatch(names.PATCH_QUESTION_TEST_SCORE, payload)
                .then(() => {
                  customToast(
                    "Sus respuestas han sido enviadas satisfactoriamente. Gracias por contestar el instrumento.",
                    "bottom-left",
                    5000
                  );
                });
            } else {
              this.save_attempt += 1;
              setTimeout(() => {
                if (this.empty_questions.length > 0) this.scrollTest();
              }, 300);
            }
          });
        } else {
          this.save_attempt += 1;
          this.$swal({
            title:
              "<p>¿Está seguro/a de que desea <strong>finalizar</strong> el instrumento?</p>",
            text: "¡Sus respuestas serán enviadas y esta acción no se podrá revertir!",
            type: "warning",
            confirmButtonText: "Sí",
            cancelButtonText: "No",
            showCancelButton: true,
          }).then((result) => {
            if (result.value) {
              let payload = {
                question_test_score_id: this.questionTestScore.id,
                item: {
                  is_finished: true,
                },
              };
              this.$store
                .dispatch(names.PATCH_QUESTION_TEST_SCORE, payload)
                .then(() => {
                  customToast(
                    "Sus respuestas han sido enviadas satisfactoriamente. Gracias por contestar el instrumento.",
                    "bottom-left",
                    5000
                  );
                });
            }
          });
        }
      }
    },
    questionDateTime() {
      this.question_datetime = this.$moment(
        this.questionTest.start_date
      ).format("YYYY-MM-DD HH:MM:SS");
    },
    checkPermissions() {
      this.can_answer = false;
      if (this.is_running && this.user.is_student && this.evaluation) {
        const sections = this.sections.filter((x) =>
          this.evaluation.sections.includes(x.id)
        );
        sections.forEach((section) => {
          if (section.students.includes(this.user.id)) {
            this.can_answer = true;
            return;
          }
        });
      }
    },
    checkTimes() {
      this.can_edit_evaluation = this.canEditEvaluation;
      if (this.time_to_start || this.time_to_end) return;
      clearTimeout();
      this.time_to_start = null;
      this.time_to_end = null;
      this.time_elapsed_to_start = null;
      this.time_elapsed_to_end = null;
      if (
        this.evaluation &&
        this.$moment(this.questionTest.due_date) > this.$moment() &&
        this.$moment(this.questionTest.start_date) < this.$moment()
      ) {
        let current_date = this.$moment();
        if (!this.$moment(this.questionTest.start_date)) {
          return;
        }
        let start_date = this.$moment(this.questionTest.start_date);
        if (start_date > current_date) {
          this.can_edit_evaluation = this.canEditEvaluation;
          if (start_date.diff(current_date) < 24 * 60 * 60 * 1000)
            this.time_to_start = start_date.diff(current_date);
        } else {
          this.is_running = true;
          this.can_edit_evaluation = false;
          this.key_component += 1;
        }
        if (!this.$moment(this.questionTest.due_date)) {
          return;
        }
        let due_date = this.$moment(this.questionTest.due_date);
        if (due_date >= current_date && this.is_running) {
          this.time_to_end = due_date - current_date;
        } else {
          this.is_running = false;
          this.key_component += 1;
        }
      } else if (
        this.evaluation &&
        this.$moment(this.questionTest.due_date) < this.$moment()
      ) {
        this.can_edit_evaluation = false;
      } else if (
        this.evaluation &&
        this.$moment(this.questionTest.start_date) > this.$moment()
      ) {
        this.evaluation_not_started_yet = true;
      }
    },
    emitCreatedSelectAnswer() {
      this.created_select_answer++;
    },
    emitCreatedRedactionAnswer() {
      this.created_redaction_answer++;
    },
    specificationTableQuestion() {
      this.key_component += 1;
    },
    printStateChanged(print_state) {
      this.print_state = print_state;
    },
    onScroll(e) {
      this.window_top = e.target.documentElement.scrollTop;
    },
    fetchQuestionTestScore() {
      let payload = {
        question_test_id: this.question_test_id,
        user_id: this.user.id,
      };
      this.$store.dispatch(names.FETCH_QUESTION_TESTS_SCORE, payload);
      // .then((response) => {
      //   if (response) {
      //     response.forEach((x) => {
      //       this.$store
      //         .dispatch(names.FETCH_QUESTION_TEST_SCORE, x.id)
      //         .then((question_test_score) => {
      //           if (
      //             question_test_score &&
      //             this.questionTest.retake_instrument
      //           ) {
      //             let payload = {
      //               question_test_score_id: question_test_score.id,
      //               item: {
      //                 is_finished: false,
      //               },
      //             };
      //             this.$store.dispatch(
      //               names.PATCH_QUESTION_TEST_SCORE,
      //               payload
      //             );
      //           }
      //         });
      //     });
      //   }
      // });
    },
    startInstrument() {
      if (this.questionTest) {
        this.$swal({
          title: "¿Desea comenzar a responder el instrumento?",
          text: "La realización de este instrumento debe contar con la autorización o consentimiento por parte del estudiante.",
          // text: "¿Desea comenzar a responder el instrumento?",
          type: "warning",
          showCancelButton: true,
          // confirmButtonText: 'Estoy seguro/a',
          confirmButtonText: "Comenzar",
          cancelButtonText: "Cancelar",
        }).then((result) => {
          if (result.value) {
            this.key_component += 1;
            this.can_answer = true;
            this.$restful
              .Post(
                `/evaluation/create_question_test_score/?question_test=${this.question_test_id}`
              )
              .then(() => {
                this.fetchQuestionTestScore();
              });
          }
        });
      }
    },
    // slotStateChanged(state) {
    //   if (state) this.is_finished = false;
    //   else this.is_finished = true;
    //   if (this.questionTestScore) {
    //     if (state) {
    //       let payload = {
    //         question_test_score_id: this.questionTestScore.id,
    //         item: {
    //           is_finished: false,
    //         },
    //       };
    //       this.$store.dispatch(names.PATCH_QUESTION_TEST_SCORE, payload);
    //     } else {
    //       let payload = {
    //         question_test_score_id: this.questionTestScore.id,
    //         item: {
    //           is_finished: true,
    //         },
    //       };
    //       this.$store.dispatch(names.PATCH_QUESTION_TEST_SCORE, payload);
    //     }
    //   }
    // },
    retakeInstrument() {
      if (this.questionTestScore) {
        let payload = {
          question_test_score_id: this.questionTestScore.id,
          item: {
            is_finished: false,
            attempted_retakes: this.questionTestScore.attempted_retakes + 1,
          },
        };
        this.$store
          .dispatch(names.PATCH_QUESTION_TEST_SCORE, payload)
          .then((response) => {
            if (response) this.fetchQuestionTestScore();
          });
      }
    },
    emitSaving(is_saving) {
      this.is_saving = is_saving;
    },
  },
  created() {
    this.fetchQuestionTestScore();
    this.$store
      .dispatch(names.FETCH_QUESTIONS, this.question_test_id)
      .then(() => {
        this.$store.dispatch(
          names.FETCH_MULTIPLE_CHOICE_QUESTIONS,
          this.question_test_id
        );
      });
    this.$store.dispatch(names.FETCH_QUESTION_TYPES);
    window.addEventListener("scroll", this.onScroll);
  },
  watch: {
    time_to_start() {
      if (this.time_to_start && this.time_to_start > 0) {
        this.time_elapsed_to_start = this.time_to_start;
        let interval = 1000;
        if (this.time_elapsed_to_start > 60 * 60 * 1000)
          interval = 60 * 60 * 1000;
        clearTimeout();
        setInterval(() => {
          this.time_elapsed_to_start -= interval;
          if (this.time_elapsed_to_start < 0) {
            clearInterval();
            this.time_elapsed_to_start = null;
            this.checkTimes();
            this.checkPermissions();
          }
        }, interval);
      } else {
        this.time_to_start = null;
      }
    },
    time_to_end() {
      if (this.time_to_end && this.time_to_end > 0) {
        this.time_elapsed_to_end = this.time_to_end;
        clearTimeout();
        setInterval(() => {
          this.time_elapsed_to_end -= 1000;
          if (this.time_elapsed_to_end < 0) {
            clearInterval();
            this.time_elapsed_to_end = null;
            this.is_running = false;
          }
        }, 1000);
      }
    },
  },
  mounted() {
    this.checkTimes();
    this.checkPermissions();
    this.fetchQuestionTestScore();
    this.$store
      .dispatch(names.FETCH_QUESTIONS, this.question_test_id)
      .then(() => {
        this.$store.dispatch(
          names.FETCH_MULTIPLE_CHOICE_QUESTIONS,
          this.question_test_id
        );
      });
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
};
</script>

<style scoped>
.test-container {
  /* background-color: var(--primary-color); */
  border-radius: 5px;
}
.card-section {
  /* border-bottom: 1px solid #ddd; */
  margin-bottom: 1.3em;
}
.card-item {
  page-break-before: auto;
  page-break-after: auto;
  page-break-inside: avoid;
}
.rounded-button {
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: var(--secondary-color);
}
.rounded-button:hover {
  background-color: var(--primary-color);
}
.rounded-button:focus {
  border: none;
  outline: none;
  background-color: var(--primary-color);
}
.rounded-button:active {
  background-color: var(--secondary-color);
}
.col-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.time-label {
  color: white;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 4px;
}
.left-time-label {
  background-color: rgb(235, 70, 70);
}
.start-time-label {
  background-color: green;
}
.not-started-yet {
  background: #daecda;
}
.test-header {
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  background-color: var(--primary-color);
  box-shadow: 0px 1px 5px 0px #b8b8b8;
}
.header-div {
  border-bottom: 1px solid var(--secondary-color);
  margin: 1px;
  padding: 2px;
  text-align: left;
}
.header-div:last-of-type {
  border-bottom: none;
}
.header-div-flex {
  display: flex;
  border-bottom: 1px solid var(--secondary-color);
}
.header-div-flex:last-of-type {
  border-bottom: none;
}
.datepicker-1 {
  width: 80%;
}
.question-date {
  background-color: rgb(228, 228, 228);
  padding: 2px 4px;
  border-radius: 4px;
}
.last-question-added {
  box-shadow: 0px 1px 3px 7px #99c9d8 !important;
  /* border: 4px solid var(--primary-color) !important; --- border transition*/
}
.test-timer {
  width: 99.6%;
  margin-inline: 0.2%;
  background: rgba(153, 201, 216, 0.5);
  font-weight: bold;
  transition: 0.2s;
}
.test-timer-sticky {
  width: 100%;
  /* margin-inline: -0.95% !important; */
  /* transform: translateX(-1.1%); */
  background: var(--primary-color);
  padding-top: 2em;
  transition: 0.2s;
  position: fixed;
  left: 0;
  top: 62px;
  z-index: 100;
}
.flex-inner {
  display: flex;
}
.questions-answered-sticky {
  margin-left: auto;
  margin-right: 0.3em;
  width: 75%;
}
.swal2-modal >>> .swal2-content {
  text-align: justify;
}
.specification-table-container {
  max-height: 0;
  transition: max-height 0.25s ease-out;
  overflow: hidden;
}
.toggle-specification-table {
  max-height: 600px !important;
  transition: max-height 0.25s ease-in;
}
.custom-modal {
  width: 2000px !important;
}
@media (max-width: 1000px) {
  .questions-answered-sticky {
    width: 100% !important;
    margin-bottom: 0.1em;
  }
}
</style>